/* Built In Imports */
import { useRouter } from 'next/router';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

/* External Imports */
import { Box, CircularProgress } from '@chakra-ui/react';
import mapValues from 'lodash/mapValues';
import trim from 'lodash/trim';
import { FormProvider, useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import config from '@config';
import { phoneWithCountryCode } from '@constants/phoneWithCountryCode';
import { analyticsEvent } from '@ieo/Components/Form/ieo/api/analyticsAPi';
import {
  formPost,
  getCallbackUrl,
} from '@ieo/Components/Form/ieo/api/apiUtils';
import {
  getPreSignedUrlForUpload,
  registerForGiftingFlow,
  registerForNewApiProgram,
  registerForProgram,
  uploadFileToS3,
} from '@ieo/Components/Form/ieo/api/registrationFormApi';
import { useSsoData } from '@ieo/Components/Form/ieo/api/ssoApi';
import { useLanguage } from '@ieo/Components/Form/ieo/context/languageContext';
import { useProgramLanguageSelection } from '@ieo/Components/Form/ieo/context/programLanguageSelectionContext';
import {
  useCountry,
  useIsIndia,
  useRegion,
} from '@ieo/Components/Form/ieo/context/regionContext';
import {
  IeoFormHeader,
  IeoFormSso,
} from '@ieo/Components/Form/ieo/elements/formComponents';
import {
  TranslationContext,
  useTranslation,
} from '@ieo/Components/Form/ieo/translation';
import { useEmailValidationLogic } from '@ieo/Components/Form/ieo/useEmailValidationLogic';
import { programTypes } from '@ieo/Components/Form/ieo/utils';
import { AuthContext } from '@store/auth-context';
import {
  formatDataForApiSubmission,
  formatDataForNewApiSubmission,
  formatDataForNewGiftingApiSubmission,
} from './ieoFormLogic';
import { Step1Form } from './step1Form';
import { Step1FormCorporates } from './step1FormCorporates';
import { Step2Form } from './step2Form';

/* Services */
import { mixPanel } from '@utils/Mixpanel';
import { postLeadEnquiry } from 'services/ieoService';

/* Styles */

const useMergeState = initialState => {
  const [state, internalSetState] = useState(initialState);
  return [
    state,
    update => internalSetState(state => ({ ...state, ...update })),
  ];
};

let defaultValues = {
  fname: '',
  lname: '',
  email: '',
  zip: '',
  country: '',
  state: '',
  city: '',
  pPhone: '',
  pCountry: '',
  sPhone: '',
  sCountry: '',
  address1: '',
  receiveMessages: false,
  terms: false,
  privacy: false,
  medical: false,
  cCode: '',
};

export const IeoRegistrationForm = ({
  programData: rawProgramData,
  EnrollSectionSt,
  couponData,
  sectionContent,
  pageData,
  continueBtn,
  pageName,
  context,
  // setContext,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const jsonContent = useContext(TranslationContext);
  const { t } = useTranslation();
  const lang = useLanguage();
  const region = useRegion();
  const isIndia = useIsIndia();
  const [isGiftingFlow, setIsGiftingFlow] = useState();
  const [couponState, setCouponState] = useState({});
  const [parentSignature, setParentSignature] = useState(null);
  // const { setContextData } = useEnrollContext();
  const [resData, setResData] = useState(null);
  const errorMsgs = {
    army: {
      emp_id_incorrect: 'Canteen Card number already used',
    },
    'corporate-register': {
      'error-ieo-exists': 'User/Account already exists',
    },
  };

  if (pageName === 'corporate') {
    defaultValues = {
      fname: '',
      lname: '',
      email: '',
      jobTitle: '',
      companyName: '',
      programType: 'IE Corporate Leads',
      country: '',
    };
  }

  // Modify data from API to add global languages, as those aren't coming from API
  // Only if outside India
  const programData = useMemo(() => {
    if (!rawProgramData) return rawProgramData;

    let newProgramData = Object.entries(rawProgramData).map(item => ({
      ...item[1],
      programId: item[0],
    }));

    if (!isIndia) {
      const firstProgram = Object.entries(rawProgramData)[0];

      newProgramData =
        // ...newProgramData,
        Object.keys(jsonContent.globalLanguages).map(language => ({
          ...firstProgram[1],
          lang: language.toUpperCase(),
          programId: firstProgram[0],
        }));
    }

    return newProgramData;
  }, [rawProgramData, isIndia, jsonContent.globalLanguages]);

  const router = useRouter();
  const { lang: langUrlParam, type: typeUrlParam } = router.query;
  const isTransfer = typeUrlParam === 'transfer';
  const isArmyOfferURL = router.asPath.includes('armed-forces');
  const isV6Url = router.asPath.includes('inner-engineering-v6');
  const isCorporateURL = router.asPath.includes(
    'inner-engineering/offer/corporate-register'
  );
  const [formState, setFormState] = useMergeState({
    step: 1,
    values: {},
    status: 'initial',
    errorMessage: undefined,
    canContinue: true,
  });

  const Mixpanel = mixPanel(router.asPath);
  const [isInitialized, setIsInitialized] = useState(false);

  const [selectedProgram, _setSelectedProgram] = useState(null);
  const setSelectedProgram = program => {
    // console.log('setSelectedProgram', program);
    if (program?.coupon?.couponCode || program?.coupon?.categoryName) {
      setFormState({
        step: 1,
        updatedProgramFee: program?.coupon?.postCouponPrice,
      });
    } else {
      setFormState({ step: 1 });
    }
    _setSelectedProgram(program);
  };
  const [hasPrefilledFirstLastEmail, setHasPrefilledFirstLastEmail] =
    useState(false);
  const [hasPrefilledFirstLastName, setHasPrefilledFirstLastName] =
    useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [originalProgramFee, setOriginalProgramFee] = useState(null);

  // Pull user's SSO profile, if they are logged in
  const authContext = useContext(AuthContext);
  const ipCountryQuery = useCountry();
  const ssoQuery = useSsoData({
    isLoginComplete: authContext.isLoginComplete,
    isLoggedIn: authContext.isLoggedIn,
  });

  // Initialize form using react hook form
  const methods = useForm({
    defaultValues: defaultValues,
    mode: 'onTouched',
  });

  const {
    handleSubmit,
    getValues,
    reset,
    watch,
    setValue,
    trigger,
    resetField,
    setError,
    formState: { isSubmitting, isValid, isDirty, errors },
  } = methods;

  // This effect sets up initial form values based on API data,
  // and turns off loading state
  useEffect(() => {
    if (
      !isInitialized &&
      programData &&
      ipCountryQuery.isSuccess &&
      ssoQuery.isSuccess
    ) {
      // console.log('useEffect', ssoQuery?.data, isInitialized, authContext, formState)
      const ipCountry = ipCountryQuery.data;
      const phoneCountryOption =
        phoneWithCountryCode.find(item => item.iso2_code === ipCountry)
          ?.phone_code || '+91';
      const countryValues = {
        pCountry: phoneCountryOption,
        sCountry: phoneCountryOption,
        country: ipCountry,
      };

      // If country is India, force country and phone dropdown to be India
      // Otherwise, SSO overrides those values

      // for youth offer page
      if (pageName === 'yo' && region === 'in') {
        const fullAddress = `${context?.address?.house}${
          context?.address?.street && ','
        } ${context?.address.street}${context?.address.landmark && ','} ${
          context?.address.landmark || ''
        }${context?.address.locality && ','} ${context?.address.locality}`;
        defaultValues.country = context?.address?.country;
        defaultValues.city = context?.address?.vtc;
        defaultValues.state = context?.address?.state;
        defaultValues.address1 = fullAddress;
        const nameVal = context?.name.trim().split(' ');
        defaultValues.fname = nameVal[0];
        defaultValues.lname =
          nameVal.length > 1 ? nameVal.slice(1).join(' ') : '';

        if (
          ssoQuery.data?.fname &&
          ssoQuery.data.lname &&
          ssoQuery.data.email
        ) {
          setHasPrefilledFirstLastName(true);
        }
      }

      const newValues =
        ipCountry === 'IN'
          ? {
              ...defaultValues,
              ...ssoQuery.data,
              ...countryValues,
            }
          : {
              ...defaultValues,
              ...countryValues,
              ...ssoQuery.data,
            };

      reset(newValues);

      if (pageName === 'corporate-register') {
        setFormState({
          canContinue: true,
          showEmailValidationMessage: false,
          emailValidationResponse: {
            status: 0,
            regType: 'New',
          },
        });
        setFormState({
          values: {
            ...formState.values,
            ...ssoQuery.data,
          },
        });
      }

      if (ssoQuery.data?.fname && ssoQuery.data.lname && ssoQuery.data.email) {
        setHasPrefilledFirstLastEmail(true);
      }

      // Select program language based on URL param or region part of URL (default to English)
      if (!selectedProgram) {
        let cpnLang = '';
        if (pageName === 'corporate-register') {
          cpnLang = (couponData?.cpnLanguage || 'EN').toUpperCase();
        }

        const findProgram = language =>
          programData.find(item => item.lang === language);
        const searchLanguage = (langUrlParam || lang).toUpperCase();
        const newProgram =
          pageName === 'corporate-register' && cpnLang
            ? findProgram(cpnLang) || findProgram('EN')
            : findProgram(searchLanguage) || findProgram('EN');
        setSelectedProgram(newProgram);
      }
      setIsInitialized(true);
      // console.log('authContext', authContext, ssoQuery.data);
      if (authContext?.ssoSessionId) {
        if (Object.keys(Mixpanel)?.length > 0) {
          Mixpanel.track('ie_registration_social_form_submit', {});
        }
      }
    }
  }, [
    isInitialized,
    reset,
    programData,
    selectedProgram,
    ipCountryQuery.isSuccess,
    ipCountryQuery.data,
    ssoQuery.isSuccess,
    ssoQuery.data,
    setSelectedProgram,
  ]);

  useEffect(() => {
    // console.log('setOriginalProgramFee?.pgmFee', originalProgramFee, selectedProgram?.discountedPrice, selectedProgram?.pgmFee)
    setOriginalProgramFee(
      selectedProgram?.discountedPrice ?? selectedProgram?.pgmFee
    );
  }, [selectedProgram?.discountedPrice, selectedProgram?.pgmFee]);

  useEffect(() => {
    if (router.asPath.indexOf('/gift') > -1) {
      setIsGiftingFlow(true);
    }
  }, [router.asPath]);

  // Regional customizations to form fields
  const [country, whatsappSame] = watch(['country', 'whatsappSame']);
  const hideStatePicker = country === 'FR';
  const disableCountryPicker = isIndia;

  // Copy primary phone number to whatsapp field if "whatsapp same as phone" checkbox is checked
  useEffect(() => {
    if (whatsappSame) {
      const values = getValues();
      setValue('sCountry', values.pCountry);
      setValue('sPhone', values.pPhone);
      trigger('sCountry');
      trigger('sPhone');
    }
  }, [getValues, setValue, whatsappSame]);

  // Change selected program when it's changed in dropdown (outside of this form)
  const { programLanguage } = useProgramLanguageSelection();
  const prevSelectedLanguage = useRef(programLanguage);

  function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  useEffect(() => {
    if (
      isInitialized &&
      programLanguage &&
      selectedProgram?.lang !== programLanguage &&
      prevSelectedLanguage.current !== programLanguage
    ) {
      prevSelectedLanguage.current = programLanguage;

      const newProgram = programData.find(
        item => item.lang.toLowerCase() === programLanguage.toLowerCase()
      );
      if (!newProgram) return;

      setSelectedProgram(newProgram);
    }
    if (isCorporateURL) {
      goToStep2();
    }
  }, [
    isInitialized,
    programData,
    programLanguage,
    selectedProgram?.lang,
    setFormState,
  ]);

  // setOriginalProgramFee(selectedProgram?.discountedPrice ?? selectedProgram?.pgmFee);
  // Program fee calculations
  const programFee = formState.updatedProgramFee ?? originalProgramFee;
  let isReducedFee = programFee === 0 || programFee < originalProgramFee;

  const feeAdjustment =
    programFee === 0 ? 'free' : isReducedFee ? 'reduced' : 'full';

  const { validateEmail } = useEmailValidationLogic(selectedProgram);

  const goToStep1 = () => {
    setFormState({
      step: 1,
      values: { ...formState.values, ...getValues() },
    });
  };

  const goToStep2 = () => {
    setCouponState({});
    analyticsEvent('ieoContinueButtonClick');
    setFormState({
      step: 2,
    });
  };

  const onSubmit = async data => {
    // console.log('form-data', data);
    try {
      setFormState({ status: 'initial', errorMessage: undefined });

      if (formState.step === 1) {
        setFormState({ values: { ...formState.values, ...data } });
        if (!isGiftingFlow) {
          const { canContinue, result, showNotice } = await validateEmail(
            data.email,
            data.fname,
            data.lname
          );

          if (result?.regType === 'Pending-Payment') {
            setFormState({
              status: 'error',
              errorMessage:
                'Payment is pending for previous request. We cannot process another registration',
            });
            return;
          }

          if (result?.status === false) {
            setFormState({ status: 'error', errorMessage: result.message });
            return;
          }
          //  console.log('result?.formState', formState, data);

          setFormState({
            canContinue,
            showEmailValidationMessage: showNotice,
            emailValidationResponse: result,
            updatedProgramFee:
              parseInt(result?.pgmFee) > -1
                ? result?.pgmFee
                : selectedProgram?.coupon?.postCouponPrice,
            updatedOldFee:
              parseInt(result?.pgmFee) > -1
                ? result?.pgmFee
                : selectedProgram?.coupon?.postCouponPrice,
          });
        }

        goToStep2();
        if (Object.keys(Mixpanel)?.length > 0) {
          Mixpanel.track('ie_registration_initiated', {
            enrollment_method: 'Manual',
          });
        }

        if (authContext?.ssoSessionId && result?.ssoId) {
          if (Object.keys(Mixpanel)?.length > 0) {
            Mixpanel.identify(authContext?.ssoId);
            Mixpanel.people.set({
              $name: `${data.fname} ${data.lname}`,
              $email: email,
            });
          }
        }
      } else if (formState.step === 2) {
        // if (!data?.armyID || parseInt(data?.armyID) > 4000000000) {
        //   // context.armyValidationFailed = true;
        //   console.log('army offer validation failed', context);
        //   // setContext(context);
        //   setContextData({ validationFailed: true });
        //   return;
        // }
        if (isGiftingFlow && couponState?.message) {
          return;
        }
        if (data?.cCode && !couponState.msg && isDirty && isValid) {
          setError(
            'cCode',
            { type: 'focus', message: 'Voucher code is not Applied' },
            { shouldFocus: true }
          );
          return;
        }
        setSubmitted(true);
        const values = {
          ...formState.values,
          ...data,
          ...(disableCountryPicker && { country: 'IN' }),
          ...(hideStatePicker && { state: 'N/A' }),
        };
        const whiteSpacesRemovedObj = mapValues(values, function (value) {
          return trim(value);
        });
        setFormState({ values: whiteSpacesRemovedObj });

        const price = programFee;

        const { registrationType } = data;
        let res = {};

        if (pageName === 'yo') {
          if (region !== 'in') {
            let fileUploaded = null;
            if (!data?.parent?.file?.name) {
              return;
            }
            if (data?.parent?.file?.name) {
              let fileParams = await getPreSignedUrlForUpload({
                filename: `${data?.parent?.file?.name.replace(
                  / /g,
                  '_'
                )}_${new Date().toLocaleString()}_id`,
                filetype: data?.parent?.file?.type || 'application/pdf',
                purpose: 'ie_7_steps_youth',
              });

              // upload file
              const formData = new FormData();
              fileParams.fields.key = fileParams.key.replace(/ /g, '_');

              Object.keys(fileParams.fields).forEach(key => {
                formData.append(key, fileParams.fields[key]);
              });
              // console.log('uploading file ', fileParams, data);
              formData.append('file', data?.parent?.file);
              fileUploaded = await uploadFileToS3(fileParams.url, formData);
              data.parent.file = fileParams.key;

              if (data?.parent?.file && fileUploaded) {
                // Sends registration request to IEO back end
                res = await registerForProgram(
                  programTypes.IEO,
                  selectedProgram.programId,
                  formatDataForApiSubmission({
                    values: whiteSpacesRemovedObj,
                    cost: price,
                    language: selectedProgram.lang,
                    pgmCurr: selectedProgram.curr,
                    regType: formState.emailValidationResponse.regType,
                    startStepId: formState.emailValidationResponse.startStepId,
                    isIndia,
                    programCountry: ipCountryQuery.data,
                    hiddenccCode:
                      formState.emailValidationResponse.regType?.toLowerCase() ===
                      'new'
                        ? selectedProgram?.coupon?.couponCode
                        : null, // for coupon codes that are pre applied
                    kycId: context?.kycId,
                    cCode: pageName === 'yo' ? 'Youth' : '',
                    file: fileParams.key,
                    vId: isMobile ? 'MOBIL' : 'AEOI',
                    categoryRegistration: true,
                    categoryName: selectedProgram?.coupon?.categoryName,
                    categoryType: selectedProgram?.coupon?.categoryType,
                  })
                );

                if (Object.keys(Mixpanel)?.length > 0) {
                  Mixpanel.track('ie_registration_details_submit', {
                    program_language:
                      config.lngList[selectedProgram.lang.toLowerCase()],
                    program_amount: price,
                    currency: selectedProgram.curr,
                    discounted_amount: formState.updatedProgramFee || price,
                    has_whatsapp: whiteSpacesRemovedObj.whatsappSame,
                    voucher_code: couponState?.cCode || '',
                    liability_waiver: whiteSpacesRemovedObj?.liabilityWaiver,
                    notification_waiver: whiteSpacesRemovedObj?.receiveMessages,
                    age_confirmation: whiteSpacesRemovedObj?.age,
                    terms_condition: whiteSpacesRemovedObj?.terms,
                  });
                }
              } else {
                setFormState({
                  status: 'error',
                  errorMessage: 'Something went wrong',
                });
              }
              // console.log('file uploaded ', data, fileParams);
            }
          } else if (context?.age >= 15 && context?.age < 18) {
            let aadhaarCardUploaded = null;
            let signatureUploaded = null;
            // console.log('minor flow', pageName, context?.age, data);

            if (!parentSignature || !data?.parent?.Id_file_url?.name) {
              return;
            }

            if (data?.parent?.Id_file_url?.name) {
              let aadhaarCardParams = {};
              // get presigned url for aadhaar upload
              aadhaarCardParams = await getPreSignedUrlForUpload({
                filename: `${
                  data?.parent?.Id_file_url?.name
                }_${new Date().toLocaleString()}_id`,
                filetype: data?.parent?.Id_file_url?.type || 'application/pdf',
                purpose: 'ie_7_steps_minor_consent_giver',
              });

              // upload aadhaar card
              const formData = new FormData();
              aadhaarCardParams.fields.key = aadhaarCardParams.key;

              Object.keys(aadhaarCardParams.fields).forEach(key => {
                formData.append(key, aadhaarCardParams.fields[key]);
              });
              // console.log('uploading aadharcard ', aadhaarCardParams, data);
              formData.append('file', data?.parent?.Id_file_url);
              aadhaarCardUploaded = await uploadFileToS3(
                aadhaarCardParams.url,
                formData
              );
              data.parent.Id_file_url = aadhaarCardParams.key;
              // console.log('aadharcard uploaded ', data, aadhaarCardParams);
            }

            if (parentSignature) {
              let signatureParams = {};
              // get presigned url for signature upload
              signatureParams = await getPreSignedUrlForUpload({
                filename: `${
                  data?.fname
                }_${new Date().toLocaleString()}_signature`,
                filetype: 'image/png',
                purpose: 'ie_7_steps_minor_consent_giver',
              });

              // upload signature
              const formData1 = new FormData();
              signatureParams.fields.key = signatureParams.key;

              Object.keys(signatureParams.fields).forEach(key => {
                formData1.append(key, signatureParams.fields[key]);
              });

              const signatureBlob = dataURItoBlob(parentSignature);

              formData1.append('file', signatureBlob);
              signatureUploaded = await uploadFileToS3(
                signatureParams.url,
                formData1
              );
              data.parent.Signature_file_url = signatureParams.key;
              // console.log('parentSignature uploaded ', data, signatureParams);
            }

            if (
              parentSignature &&
              data?.parent?.Id_file_url &&
              data?.parent?.Signature_file_url &&
              signatureUploaded &&
              aadhaarCardUploaded
            ) {
              // Sends registration request to IEO back end
              res = await registerForProgram(
                programTypes.IEO,
                selectedProgram.programId,
                formatDataForApiSubmission({
                  values: whiteSpacesRemovedObj,
                  cost: price,
                  language: selectedProgram.lang,
                  pgmCurr: selectedProgram.curr,
                  regType: formState.emailValidationResponse.regType,
                  startStepId: formState.emailValidationResponse.startStepId,
                  isIndia,
                  programCountry: ipCountryQuery.data,
                  hiddenccCode:
                    formState.emailValidationResponse.regType?.toLowerCase() ===
                    'new'
                      ? selectedProgram?.coupon?.couponCode
                      : null, // for coupon codes that are pre applied
                  kycId: context?.kycId,
                  cCode: pageName === 'yo' ? 'YOUTH50' : '',
                  parent: data?.parent,
                  vId: isMobile ? 'MOBIL' : 'AEOI',
                })
              );

              if (Object.keys(Mixpanel)?.length > 0) {
                Mixpanel.track('ie_registration_details_submit', {
                  program_language:
                    config.lngList[selectedProgram.lang.toLowerCase()],
                  program_amount: price,
                  currency: selectedProgram.curr,
                  discounted_amount: formState.updatedProgramFee || price,
                  has_whatsapp: whiteSpacesRemovedObj.whatsappSame,
                  voucher_code: couponState?.cCode || '',
                  liability_waiver: whiteSpacesRemovedObj?.liabilityWaiver,
                  notification_waiver: whiteSpacesRemovedObj?.receiveMessages,
                  age_confirmation: whiteSpacesRemovedObj?.age,
                  terms_condition: whiteSpacesRemovedObj?.terms,
                });
              }
            } else {
              setFormState({
                status: 'error',
                errorMessage: 'Something went wrong',
              });
            }
          }
        } else if (isGiftingFlow) {
          // Sends Gifting flow registration request to IEO back end
          res = await registerForGiftingFlow(
            formatDataForNewGiftingApiSubmission({
              values: whiteSpacesRemovedObj,
              cost: price,
              language: selectedProgram.lang,
              pgmCurr: selectedProgram.curr,
              programCountry: ipCountryQuery.data,
              hiddenccCode: selectedProgram?.coupon?.couponCode, // for coupon codes that are pre applied
              vId: isMobile ? 'MOBIL' : 'AOEI',
              selectedProgram: selectedProgram.programId,
              checksum:
                couponState?.checksum || // coupon api checksum
                formState?.emailValidationResponse?.checksum || // step 1 checksum
                selectedProgram?.checksum, // pricing api checksum
            })
          );

          if (Object.keys(Mixpanel)?.length > 0) {
            Mixpanel.track('ie_registration_details_submit', {
              program_language:
                config.lngList[selectedProgram.lang.toLowerCase()],
              program_amount: price,
              currency: selectedProgram.curr,
              discounted_amount: formState.updatedProgramFee || price,
              has_whatsapp: whiteSpacesRemovedObj.whatsappSame,
              voucher_code: couponState?.cCode || '',
              liability_waiver: whiteSpacesRemovedObj?.liabilityWaiver,
              age_confirmation: whiteSpacesRemovedObj?.age,
              terms_condition: whiteSpacesRemovedObj?.terms,
            });
          }
        } else {
          const formObj = {
            values: whiteSpacesRemovedObj,
            cost: price,
            language: selectedProgram.lang,
            pgmCurr: selectedProgram.curr,
            regType: formState.emailValidationResponse.regType,
            startStepId: formState.emailValidationResponse.startStepId,
            isIndia,
            programCountry: ipCountryQuery.data,
            hiddenccCode:
              formState.emailValidationResponse.regType?.toLowerCase() === 'new'
                ? selectedProgram?.coupon?.couponCode
                : null, // for coupon codes that are pre applied
            kycId: context?.kycId,
            vId: isMobile ? 'MOBIL' : 'AOEI',
            selectedProgram: selectedProgram.programId,
            checksum:
              couponState?.checksum || // coupon api checksum
              formState?.emailValidationResponse?.checksum || // step 1 checksum
              selectedProgram?.checksum, // pricing api checksum
            nameEmailCheckSum:
              formState?.emailValidationResponse?.nameEmailCheckSum,
          };

          if (pageName === 'corporate-register' || pageName === 'army') {
            if (pageName === 'corporate-register') {
              if (formObj.values?.sPhone && !formObj.values.pPhone) {
                formObj.values.pPhone = formObj.values?.sPhone;
              }
              formObj.cost = couponData?.tCost;
              formObj.categoryRegistration = true;
              formObj.categoryName = couponData?.categoryName;
              formObj.hiddenccCode = couponData?.cCode;
            } else if (pageName === 'army') {
              formObj.categoryRegistration = true;
              formObj.categoryName = 'IASC';
              formObj.categoryType = 'Army';
              formObj.employeeId = formState?.values?.canteenCardNumber;
            }
            // Sends registration request to IEO back end
            res = await registerForProgram(
              programTypes.IEO,
              selectedProgram.programId,
              formatDataForApiSubmission(formObj)
            );
          } else {
            formObj.vId = isMobile ? 'MOBIL' : 'AOEI';
            formObj.ssoId = formState?.emailValidationResponse?.ssoId;
            // console.log(
            //   'formState?.emailValidationResponse',
            //   formState?.emailValidationResponse,
            //   formObj
            // );
            // Sends registration request to IEO back end
            res = await registerForNewApiProgram(
              formatDataForNewApiSubmission(formObj)
            );
          }
          // console.log('reg - form', formObj, res);

          if (Object.keys(Mixpanel)?.length > 0) {
            Mixpanel.track('ie_registration_details_submit', {
              program_language: config.lngList[formObj?.language.toLowerCase()],
              program_amount: formObj.cost,
              currency: selectedProgram.curr,
              discounted_amount: formState.updatedProgramFee || formObj.cost,
              has_whatsapp: formObj?.values.whatsappSame,
              voucher_code: couponState?.cCode || '',
              liability_waiver: formObj?.values.liabilityWaiver,
              notification_waiver: formObj?.values.receiveMessages,
              age_confirmation: formObj?.values.age,
              terms_condition: isIndia
                ? formObj?.values.terms
                : formObj?.values.privacy,
            });
          }
        }

        if (price === 0 || pageName === 'corporate-register') {
          if (!res.token) {
            const err = new Error(
              'Unexpected register API response, it is missing token.',
              res
            );
            throw err;
          }

          // Save the API response in session storage for use on thank you page
          sessionStorage.setItem(
            'registrationData',
            JSON.stringify({
              ...res,
              pgmTypeId: programTypes.IEO,
              isResProgram: false,
            })
          );

          // Go to thank you page directly, no GPMS
          if (isGiftingFlow) {
            router.push(`/${region}/${lang}/inner-engineering/gift/thankyou`);
          } else {
            window.location.href = `${config.BASE_PATH}/${region}/${lang}/inner-engineering/register/thankyou`;
          }
        } else {
          // We specifically omit token from the GPMS payload
          if (isGiftingFlow) {
            res.languageCode = lang.toUpperCase();
          }
          const { token, ...rest } = res;
          // console.log('reg - form', res, {
          //   ...rest,
          //   callbackUrl:
          //     getCallbackUrl({
          //       country: values.country,
          //       // TODO: Use page language here
          //       language: lang,
          //       region,
          //       url: isGiftingFlow
          //         ? '/inner-engineering/gift/thankyou'
          //         : '/inner-engineering/register/thankyou',
          //     }) +
          //     `&token=${token}&pgmId=${programTypes.IEO}&fullPrice=${originalProgramFee}&programLanguage=${selectedProgram.lang}&registrationType=${registrationType}&flow=${pageName}&has_whatsapp=${whatsappSame}`,
          // });

          // Send user to GPMS
          formPost(config.NEXT_PUBLIC_GPMS_URL, {
            ...rest,
            callbackUrl:
              getCallbackUrl({
                country: values.country,
                // TODO: Use page language here
                language: lang,
                region,
                url: isGiftingFlow
                  ? '/inner-engineering/gift/thankyou'
                  : '/inner-engineering/register/thankyou',
              }) +
              `&token=${token}&pgmId=${programTypes.IEO}&fullPrice=${originalProgramFee}&programLanguage=${selectedProgram.lang}&registrationType=${registrationType}&flow=${pageName}&has_whatsapp=${whatsappSame}`,
          });

          // Return unresolved promise to continue showing loading state
          // When we do the form post above, it should take to another page
          // eslint-disable-next-line no-undef
          return new Promise(() => {});
        }
      }
    } catch (e) {
      console.log(
        'ee',
        e?.response?.data?.error?.message,
        e?.response?.data?.error?.detail?.description,
        errorMsgs[e?.response?.data?.error?.message]
      );
      let message =
        (e?.response?.data?.error?.message &&
          `${e?.response?.data?.error?.message}: ${e?.response?.data?.error?.detail?.description}`) ||
        e?.message ||
        'There was an error with the registration';

      const errorM =
        errorMsgs[pageName] &&
        errorMsgs[pageName][e?.response?.data?.error?.message];
      if (
        errorM &&
        errorM !== undefined &&
        errorM != undefined &&
        errorM != 'undefined'
      ) {
        message = errorM;
      }
      setFormState({ status: 'error', errorMessage: message });
      console.log({
        error: e,
        message,
      });
    }
  };

  const onSubmitCorporate = async data => {
    const enquiryData = {
      message: {
        systemId: '110',
        programType: 'IE Corporate Leads',
        name: `${data.fname} ${data.lname}`,
        phone: '',
        whatsapp: '',
        email: data.workEmail || '',
        jobTitle: data.jobTitle || '',
        companyName: data.companyName || '',
        country: data.country,
        utmCampaign: '',
        utmMedium: '',
        utmSource: '',
        utmTerm: '',
        utmReferer: '',
        programYear: '2024',
      },
    };
    try {
      const response = await postLeadEnquiry(enquiryData);
      if (response) {
        setResData(response);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const formOnSubmit = handleSubmit(onSubmit);
  const formCorporateOnSubmit = handleSubmit(onSubmitCorporate);

  let errorMessage = formState.status === 'error' && formState.errorMessage;

  if (!isValid) {
    errorMessage = t('formContent.checkForm');
  }

  // Show loading spinner until form is loaded and initialized
  if (!isInitialized) {
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        minHeight={'500px'}
      >
        <CircularProgress isIndeterminate={true} />
      </Box>
    );
  }

  if (pageName === 'corporate') {
    return (
      <FormProvider {...methods}>
        <Step1FormCorporates
          onSubmit={formCorporateOnSubmit}
          errorMessage={errorMessage}
          isSubmitting={isSubmitting}
          disableCountryPicker={disableCountryPicker}
          programData={programData}
          pageData={pageData}
          region={region}
          lang={lang}
          disableFirstLastEmailFields={hasPrefilledFirstLastEmail}
          hasPrefilledFirstLastName={hasPrefilledFirstLastName}
          originalProgramFee={originalProgramFee}
          updatedProgramFee={formState.updatedProgramFee}
          isReducedFee={isReducedFee}
          isTransfer={isTransfer}
          program={selectedProgram}
          setValue={setValue}
          resDataCorp={resData}
          successMsg={{
            heading: 'Thank You for your Interest !',
            subText: 'Your form is successfully submitted',
            subHeading: 'We will get in touch with you shortly.',
          }}
        />
      </FormProvider>
    );
  }

  return (
    <>
      {!isArmyOfferURL && (
        <IeoFormHeader
          EnrollSectionSt={EnrollSectionSt}
          program={selectedProgram}
          originalProgramFee={originalProgramFee}
          updatedProgramFee={formState.updatedProgramFee}
          isReducedFee={isReducedFee}
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
          programData={programData}
          canChangeLanguage={true}
          step={formState.step}
          showPrice={
            !isCorporateURL &&
            (!isTransfer || (formState.step === 2 && formState.canContinue))
          }
          isTransfer={isTransfer}
          isGiftingFlow={isGiftingFlow}
          pageName={pageName}
          region={region}
          lang={lang}
        />
      )}

      {formState.step === 1 &&
        (!authContext.isLoggedIn && pageName !== 'yo' && !isV6Url ? (
          <IeoFormSso
            EnrollSectionSt={EnrollSectionSt}
            pageData={pageData}
            isGiftingFlow={isGiftingFlow}
            continueBtn={continueBtn}
            program={selectedProgram}
          />
        ) : (
          <Box mt={'40px'} />
        ))}

      <FormProvider {...methods}>
        {formState.step === 1 ? (
          <Step1Form
            onSubmit={formOnSubmit}
            errorMessage={errorMessage}
            isSubmitting={isSubmitting}
            disableCountryPicker={disableCountryPicker}
            programData={programData}
            pageData={pageData}
            region={region}
            lang={lang}
            disableFirstLastEmailFields={hasPrefilledFirstLastEmail}
            hasPrefilledFirstLastName={hasPrefilledFirstLastName}
            originalProgramFee={originalProgramFee}
            updatedProgramFee={formState.updatedProgramFee}
            isReducedFee={isReducedFee}
            isTransfer={isTransfer}
            program={selectedProgram}
            pageName={pageName}
            setValue={setValue}
          />
        ) : (
          <Step2Form
            values={formState.values}
            onSubmit={formOnSubmit}
            program={selectedProgram}
            onClickEditPrevious={goToStep1}
            errorMessage={errorMessage}
            isSubmitting={isSubmitting}
            hideStatePicker={hideStatePicker}
            showFormFields={formState.canContinue}
            showEmailValidationMessage={formState.showEmailValidationMessage}
            emailValidationResponse={formState.emailValidationResponse}
            programFee={programFee}
            feeAdjustment={feeAdjustment}
            canContinue={formState.canContinue}
            disableWhatsappField={whatsappSame}
            sectionContent={sectionContent}
            pageData={pageData}
            disableFirstLastEmailFields={hasPrefilledFirstLastEmail}
            originalProgramFee={originalProgramFee}
            updatedProgramFee={formState.updatedProgramFee}
            isReducedFee={isReducedFee}
            formState={formState}
            setFormState={setFormState}
            setSelectedProgram={_setSelectedProgram}
            isGiftingFlow={isGiftingFlow}
            resetField={resetField}
            couponState={couponState}
            setCouponState={setCouponState}
            watch={watch}
            setValue={setValue}
            getValues={getValues}
            context={context}
            parentSignature={parentSignature}
            setParentSignature={setParentSignature}
            pageName={pageName}
            isDirty={isDirty}
            submitted={submitted}
            region={region}
            lang={lang}
          />
        )}
      </FormProvider>
    </>
  );
};
