/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

/* External Imports */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Image,
  Link,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { useFormContext } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { RegionList } from '@constants/RegionList';
import { usePageType } from '@hooks/usePageType';
import { verifyCoupon } from '@ieo/Components/Form/ieo/api/registrationFormApi';
import { stateList } from '@ieo/Components/Form/ieo/constants/stateList';
import { useIsIndia } from '@ieo/Components/Form/ieo/context/regionContext';
import { IeoFormFieldGrid } from '@ieo/Components/Form/ieo/elements/formComponents';
import {
  DisclaimerWaiverField,
  ReleaseWaiverField,
} from '@ieo/Components/Form/ieo/elements/scrollableTerms';
import { useTranslation } from '@ieo/Components/Form/ieo/translation';
import { EmailValidationMessage } from '@ieo/Components/Form/ieo/useEmailValidationLogic';
import { programTypes } from '@ieo/Components/Form/ieo/utils';
import {
  FormErrorMessage,
  SubmitButton,
  addressCharactersValidation,
  blankSpaceValidation,
  cityCharactersValidation,
  couponCodeValidation,
  emailCharactersValidation,
  englishCharactersValidation,
} from './formElements';
import {
  HookFormCheckboxField,
  HookFormFileUploadField,
  HookFormHiddenField,
  HookFormLanguageSelectField,
  HookFormPhoneNumberField,
  HookFormStateSelectField,
  HookFormTextField,
} from './formFields';
/* Services */
import { searchByFieldId } from '@components/Utility/Shared/SharedService';
import { getStateFromPincode } from 'services/ieoService';

/* Styles */

/**
 *
 * @param {*} param0
 * @returns
 */
export function Step2Form({
  values,
  onSubmit,
  program,
  onClickEditPrevious,
  errorMessage,
  isSubmitting,
  isDirty,
  hideStatePicker,
  showEmailValidationMessage,
  emailValidationResponse,
  canContinue,
  disableWhatsappField,
  pageData,
  programFee,
  feeAdjustment,
  sectionContent,
  isReducedFee,
  updatedProgramFee,
  originalProgramFee,
  disableFirstLastEmailFields,
  formState,
  setFormState,
  setSelectedProgram,
  isGiftingFlow,
  resetField,
  watch,
  setValue,
  getValues,
  couponState,
  setCouponState,
  parentSignature,
  setParentSignature,
  context,
  region,
  lang,
  pageName,
  submitted,
}) {
  const debounceTimeout = useRef(null);
  const { t } = useTranslation();
  const isIndia = useIsIndia();
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [ccCodeColor, setCcCodeColor] = useState('#5B5237');
  const watchCcode = watch('cCode');
  const watchzip = watch('zip');
  const { trigger } = useFormContext();
  const { isGiftPage } = usePageType();
  const router = useRouter();
  const [isCanvasVis, setIsCanvasVis] = useState(false);
  // console.log('context', context);
  const isArmyOfferURL = router.asPath.includes('armed-forces');
  const [langList, setLangList] = useState([]);
  const transProviderObj =
    (pageData._allSharedJsonLocales?.length &&
      pageData._allSharedJsonLocales[0]?.value.json) ||
    pageData.sharedJson?.json;

  const objLangList = Object?.values(transProviderObj?.languages);
  const stateCountry = region.toUpperCase();
  useEffect(() => {
    if (watchCcode) {
      if (watchCcode.length > 3) {
        setCcCodeColor('#00A171');
      }
    } else {
      resetField('cCode');
      setCouponState({});
      setCcCodeColor('#5B5237');
    }
  }, [getValues, setValue, watchCcode]);

  useEffect(() => {
    if (router.query?.vouchercode) {
      // const values = getValues();
      setValue('cCode', router.query?.vouchercode);
    }
  }, [router.query?.vouchercode]);

  const verifyCouponCode = async () => {
    if (!couponState?.message && !couponState.msg && watchCcode) {
      // console.log('Axios1111', couponState);
      let res = {};
      try {
        res = await verifyCoupon({
          // pgmType: 99,
          pgmId: program?.programId,
          cCode: watchCcode,
          // noPart: 1,
          // lang: program?.lang,
          pgmCtry: values.country,
          src: isGiftingFlow ? 'gift-registration' : 'program-registration',
        });
        // console.log('Axios verifyCoupon', res);
        setCouponState(res);
        setFormState({
          updatedProgramFee: res?.eachPrice,
        });
        program.curr = res?.pmtCurr;
        setSelectedProgram(program);
      } catch (e) {
        // console.log('Axios error', e.response?.data.error);
        setCouponState(e.response?.data.error);
      }
      setCcCodeColor('#CF4520');
    } else {
      setFormState({
        updatedProgramFee:
          formState?.updatedOldFee || program?.coupon?.postCouponPrice || null,
      });
      setCouponState({});
      resetField('cCode');
    }
  };

  const onEditClick = () => {
    setCouponState({});
    onClickEditPrevious();
    let updateState = {};
    if (formState?.updatedOldFee) {
      updateState = {
        updatedProgramFee: formState?.updatedOldFee,
        status: 'coupon-removed',
      };
    }
    setFormState(updateState);
  };

  let itemCatg3 = 'New_User';
  const finalAmount = isReducedFee ? updatedProgramFee : originalProgramFee;
  if (!finalAmount) {
    itemCatg3 = 'Free_User';
  } else if (disableFirstLastEmailFields) {
    itemCatg3 = 'Repeat_User';
  }

  const gtmClick = () => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: isGiftPage ? 'IE_Pay_Securely_Gift' : 'IE_Pay_Securely',
        IE_Country: values.country,
        coupon_type: couponState?.cpnType || '',
        coupon_code: couponState.cCode || '',
      });

      window.dataLayer.push({ ecommerce: null }); // Clear the previous e-commerce object.
      window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
          currency: program.curr, // currency code (Ex - INR,USD,AUD etc.)
          value: finalAmount,
          items: [
            {
              item_id: programTypes.IEO, // id of the program
              item_name: isGiftPage
                ? 'Gift Inner Engineering'
                : 'TYLS Inner Engineering', // Name of the program (Ex - TYLS Inner Engineering)
              affiliation: '',
              coupon: couponState?.cCode || '',
              discount: finalAmount, //Actual Discount if any (actual price - pay price)
              index: '',
              item_brand: values.country || '', // Country Code (Ex - US,IN etc.)
              item_category: program?.lang, // Language (Ex - English,Spanish etc.)
              item_category2: '', // Bay Name (not reuired for IE)
              item_category3: itemCatg3, // New_User or Repeat_User
              item_category4: '',
              item_category5: RegionList[pageData.country] || 'Americas',
              item_list_id: '',
              item_list_name: '',
              item_variant: couponState?.cpnType || '',
              location_id: '',
              price: originalProgramFee,
              quantity: '',
            },
          ],
        },
      });
    }
  };

  const sigRef = useRef();
  const handleSignatureEnd = () => {
    setParentSignature(sigRef.current.toDataURL());
  };
  const clearSignature = () => {
    sigRef.current.clear();
    setParentSignature(null);
  };

  // useEffect(() => {
  //   console.log('==>', parentSignature);
  // }, [parentSignature]);

  useEffect(() => {
    if (!getStateFromPincode) {
      return;
    }
    // Clear the previous timeout if it exists
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    // New time out Set
    debounceTimeout.current = setTimeout(async () => {
      try {
        const result = await getStateFromPincode(watchzip, region);
        if (result) {
          const country = region.toUpperCase();
          if (country === 'IN') {
            setValue('state', result.state);
            setValue('city', result.city);
          } else {
            const options = [...stateList[country]];
            const state = options.find(state => state.label === result.state);
            setValue('state', state.value);
            setValue('city', result.city);
          }
        }
      } catch (error) {
        console.error('Error fetching state from pincode:', error);
      }
    }, 1000); // one second delay
    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [watchzip, getStateFromPincode, setValue, region]);

  return (
    <form onSubmit={onSubmit}>
      <VStack
        spacing={pageName === 'army' ? 0 : 6}
        p={{ base: '12px', md: '40px' }}
        alignItems={'stretch'}
        pb={10}
      >
        {showEmailValidationMessage && (
          <EmailValidationMessage
            status={emailValidationResponse.regType}
            feeAdjustment={feeAdjustment}
            registeredLanguage={emailValidationResponse.language}
            regResponse={emailValidationResponse}
            lang={lang}
          />
        )}

        {/* Box with user details from previous step, and edit icon */}
        <Box bgColor={'#EDEDED'} p={5} position={'relative'}>
          {pageName !== 'corporate-register' && (
            <Box
              position="absolute"
              cursor="pointer"
              right={lang === 'ar' ? '0px' : '20px'}
              left={lang === 'ar' && '20px'}
              top="20px"
              onClick={() => {
                onEditClick();
              }}
            >
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 6L17.0492 3L21.623 7.5L18.5738 10.5M14 6L3.32788 16.5V21H7.90165L18.5738 10.5M14 6L18.5738 10.5"
                  stroke="#E86F34"
                  stroke-width="2.66667"
                  stroke-linecap="round"
                />
              </svg>
            </Box>
          )}
          <Box
            fontSize={'14px'}
            lineHeight={'1.6'}
            textAlign={lang === 'ar' ? 'right' : 'left'}
            fontFamily={'FedraSansStd-medium'}
            data-test-id={'step1-registration-address'}
          >
            <span data-test-id={'step1-registration-email'}>
              {values.email}
            </span>
            <br />
            <span data-test-id={'step1-registration-fname'}>
              {values.fname}
            </span>{' '}
            <span data-test-id={'step1-registration-lname'}>
              {values.lname}
            </span>
            <br />
            <span data-test-id={'step1-registration-phone'}>
              {values.pPhone}
            </span>
            <br />
            <span data-test-id={'step1-registration-country'}>
              {values.country}
            </span>
          </Box>
        </Box>

        {canContinue && (
          <>
            <HookFormHiddenField
              name={'registrationType'}
              required
              testId={'hidden-reg-type-input'}
              defaultValue={itemCatg3}
            />
            <Box display="flex" py="30px">
              {isIndia && (
                <HookFormCheckboxField
                  name={'whatsappSame'}
                  label={t('formFields.whatsappSame')}
                  required={false}
                  lang={lang}
                />
              )}
              {isIndia && (
                <Tooltip
                  bg="white"
                  borderRadius="0px"
                  color="#757373"
                  fontFamily="FedraSansStd-book"
                  label="In certain Countries, Isha Volunteer may need to communicate with you via whatsApp"
                >
                  <Box cursor="pointer">
                    {' '}
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.75 11H12L12 16.25M21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12Z"
                        stroke="#3652B8"
                        stroke-width="1.5"
                        stroke-linecap="square"
                      />
                      <rect
                        x="11.375"
                        y="7.375"
                        width="1.25"
                        height="1.25"
                        fill="#3652B8"
                        stroke="#3652B8"
                        stroke-width="0.25"
                      />
                    </svg>
                  </Box>
                </Tooltip>
              )}
            </Box>
            <IeoFormFieldGrid>
              {isIndia && (
                <>
                  <Box
                    gridColumn={{
                      base: isArmyOfferURL ? '1/3' : '1/5',
                      md: '1',
                    }}
                  >
                    <HookFormPhoneNumberField
                      name={'sPhone'}
                      countryFieldName={'sCountry'}
                      label={t('formFields.whatsappNumber')}
                      testId={'sPhone-input'}
                      required
                      disabled={disableWhatsappField}
                      invalidMessage={t(
                        'validationMessages.whatsappNumber.invalid'
                      )}
                      dir={lang == 'ar' ? 'rtl' : 'ltr'}
                    />{' '}
                  </Box>

                  {isArmyOfferURL && (
                    <Box
                      gridColumn={{
                        base: '1/3',
                        md: '2',
                      }}
                    >
                      <HookFormLanguageSelectField
                        name={'programLang'}
                        label={t('formFields.programLang')}
                        required
                        langList={objLangList}
                        // rules={{ maxLength: 100 }}
                        testId={'programLang-input'}
                      />
                    </Box>
                  )}
                </>
              )}
              {isArmyOfferURL && (
                <Box
                  fontSize={{ base: '16px', md: '20px' }}
                  color="#5B5237"
                  mt={2}
                  gridColumn="1 / 3"
                >
                  {' '}
                  Address Details
                </Box>
              )}
              <Box
                gridColumn={{
                  base: isArmyOfferURL ? '1/3' : '1/5',
                  md: isArmyOfferURL ? '1' : !isIndia ? '1' : '2',
                }}
              >
                <HookFormTextField
                  name={'zip'}
                  label={t('formFields.postalCode')}
                  required
                  rules={{
                    maxLength: 10,
                    validate: {
                      length: value =>
                        isIndia
                          ? (value.length === 6 && /^\d*$/.test(value)) ||
                            t('validationMessages.postalCode.pattern')
                          : null,
                    },
                  }}
                  testId={'zip-input'}
                  dir={lang == 'ar' ? 'rtl' : 'ltr'}
                />
              </Box>

              {!hideStatePicker && (
                <Box
                  gridColumn={{
                    base: isArmyOfferURL ? '1/3' : '1/5',
                    md: !isIndia ? '2' : '1',
                  }}
                >
                  <HookFormStateSelectField
                    name={'state'}
                    label={isIndia ? t('formFields.state') : 'State / Province'}
                    required
                    country={
                      pageName === 'corporate-register'
                        ? stateCountry
                        : values.country
                    }
                    rules={{ maxLength: 100 }}
                    testId={'state-input'}
                    textAlign={lang == 'ar' && 'right'}
                  />
                </Box>
              )}
              <Box
                gridColumn={{
                  base: isArmyOfferURL ? '1/3' : '1/5',
                  md: !isIndia ? '1' : '2',
                }}
              >
                <HookFormTextField
                  name={'city'}
                  label={t('formFields.city')}
                  required
                  rules={{
                    pattern: {
                      value: cityCharactersValidation.value,
                      message: t(cityCharactersValidation.message),
                    },
                    maxLength: 100,
                  }}
                  testId={'city-input'}
                  dir={lang == 'ar' ? 'rtl' : 'ltr'}
                />
              </Box>
              <Box
                gridColumn={{
                  base: isArmyOfferURL ? '1/3' : '1/5',
                  md: '1/3',
                }}
              >
                {isIndia && (
                  <HookFormTextField
                    name={'address1'}
                    label={t('formFields.address')}
                    required
                    rules={{
                      pattern: {
                        value: addressCharactersValidation.value,
                        message: t(addressCharactersValidation.message),
                      },
                      minLength: isIndia ? 15 : 1,
                      maxLength: 100,
                    }}
                    testId={'address1-input'}
                    dir={lang == 'ar' ? 'rtl' : 'ltr'}
                  />
                )}
                {!isIndia && (
                  <HookFormTextField
                    name={'address1'}
                    label={t('formFields.address')}
                    testId={'address1-input'}
                  />
                )}
              </Box>
              {!isIndia && pageName === 'yo' && (
                <Box gridColumn={'1 / -1'} py="8px">
                  <Box
                    mb="10px"
                    fontSize="14px"
                    color="#5B5237"
                    textAlign={lang == 'ar' && 'right'}
                    dir={lang == 'ar' ? 'rtl' : 'ltr'}
                  >
                    {t('formFields.studendIDHeading')}
                  </Box>
                  <HookFormFileUploadField
                    name={'parent.file'}
                    fontSize={{ base: '14px', md: '16px' }}
                    testId={'enterCode-upload'}
                    placeholder={t('formFields.studendID')}
                    _placeholder={{ color: '#9B9685' }}
                    color="#231F20"
                    borderRadius="0px"
                    bg="#F1EBDF"
                    border={'solid 1px'}
                    borderColor={'#F1EBDF'}
                    _hover={{
                      borderColor: '#F1EBDF',
                    }}
                    _focus={{
                      borderColor: '#F1EBDF',
                    }}
                    accept="image/png, image/jpeg, image/jpg, application/pdf"
                    p={'16px'}
                    errorBorderColor={'#FF0000'}
                    resetField={resetField}
                    rules={{
                      required: t('validationMessages.required'),
                      validate: {
                        blankSpace: file =>
                          file.size > 2097152
                            ? t(
                                'validationMessages.youthParentAadharCard.invalid'
                              )
                            : file.type !== 'application/pdf' &&
                              file.type !== 'image/jpg' &&
                              file.type !== 'image/png' &&
                              file.type !== 'image/jpeg'
                            ? t(
                                'validationMessages.youthParentAadharCard.default'
                              )
                            : null,
                      },
                    }}
                    required={true}
                  />
                  <StructuredTextParser
                    mt="8px"
                    str={render(
                      searchByFieldId(
                        sectionContent,
                        'student-offer-verification'
                      )?.body
                    )}
                    maxW="auto"
                    fontSize="14px"
                    color="#28231E"
                    fontFamily="'FedraSansStd-book-i'"
                    textAlign={lang === 'ar' && 'right'}
                  />
                </Box>
              )}

              {pageName === 'yo' &&
                region === 'in' &&
                context?.age < 18 &&
                context?.age >= 15 && (
                  <>
                    <Box gridColumn={'1 / -1'} pt="20px" pb="8px">
                      <Box
                        fontFamily="FedraSansStd-medium"
                        fontStyle="normal"
                        fontWeight="500"
                        fontSize={{ base: '18px', md: '20px' }}
                        lineHeight="1.5em"
                        color="#433F3C"
                      >
                        Parental / Guardian Consent
                      </Box>
                      <Box
                        fontFamily="'FedraSansStd-book'"
                        fontSize={{ base: '14px', md: '16px' }}
                        lineHeight="1.5em"
                      >
                        To be filled only by the Parent / Guardian
                      </Box>
                    </Box>

                    <HookFormTextField
                      name={'parent.Name'}
                      label={t('formFields.youthParentName')}
                      required
                      rules={{
                        required: t('validationMessages.youthParentName'),
                        validate: {
                          blankSpace: value =>
                            blankSpaceValidation.value.test(value) ||
                            t(blankSpaceValidation.message),
                          englishCharacters: value =>
                            isIndia
                              ? englishCharactersValidation.value.test(value) ||
                                t(englishCharactersValidation.message)
                              : null,
                        },
                        maxLength: 100,
                        minLength: isIndia ? 3 : 1,
                      }}
                      fontSize={{ base: '12px', sm: '14px', md: '16px' }}
                      testId={'parent-input'}
                      dir={lang == 'ar' ? 'rtl' : 'ltr'}
                    />
                    <HookFormTextField
                      name={'parent.Email'}
                      label={t('formFields.youthParentEmail')}
                      required
                      rules={{
                        pattern: {
                          value: emailCharactersValidation.value,
                          message: t(emailCharactersValidation.message),
                        },
                        maxLength: 120,
                      }}
                      fontSize={{ base: '12px', sm: '14px', md: '16px' }}
                      testId={'parent-input'}
                      dir={lang == 'ar' ? 'rtl' : 'ltr'}
                    />
                    <Box gridColumn={'1 / -1'} mt="5px">
                      {/* {isIndia && (
                  <HookFormTextField
                    name={'Parent / Guardian’s Aadhaar card'}
                    label={t('')}
                    required
                    rules={{
                      pattern: {
                        value: addressCharactersValidation.value,
                        message: t(addressCharactersValidation.message),
                      },
                      minLength: isIndia ? 15 : 1,
                      maxLength: 100,
                    }}
                    testId={'address1-input'}
                    dir={lang == 'ar' ? 'rtl' : 'ltr'}
                  />
                )} */}
                      <HookFormFileUploadField
                        name={'parent.Id_file_url'}
                        fontSize={{ base: '14px', md: '16px' }}
                        testId={'enterCode-upload'}
                        placeholder={t('formFields.youthParentAadhar')}
                        _placeholder={{ color: '#9B9685' }}
                        color="#231F20"
                        borderRadius="0px"
                        bg="#F1EBDF"
                        border={'solid 1px'}
                        borderColor={'#F1EBDF'}
                        _hover={{
                          borderColor: '#F1EBDF',
                        }}
                        _focus={{
                          borderColor: '#F1EBDF',
                        }}
                        accept="image/png, image/jpeg, image/jpg, application/pdf"
                        p={'16px'}
                        errorBorderColor={'#FF0000'}
                        resetField={resetField}
                        rules={{
                          required: t('validationMessages.required'),
                          validate: {
                            blankSpace: file =>
                              file.size > 2097152
                                ? t(
                                    'validationMessages.youthParentAadharCard.invalid'
                                  )
                                : file.type !== 'application/pdf' &&
                                  file.type !== 'image/jpg' &&
                                  file.type !== 'image/png' &&
                                  file.type !== 'image/jpeg'
                                ? t(
                                    'validationMessages.youthParentAadharCard.default'
                                  )
                                : null,
                          },
                        }}
                        required={true}
                      />
                    </Box>
                    <Box gridColumn={'1 / -1'}>
                      <Box
                        fontFamily="'FedraSansStd-book'"
                        fontSize={{ base: '14px', md: '16px' }}
                        lineHeight="136%"
                        color="#433F3C"
                        py="10px"
                      >
                        {t('formFields.youthParentSignature')}
                      </Box>
                      {!isCanvasVis && (
                        <Box
                          position="relative"
                          maxW="302.91px"
                          w="100%"
                          height="131px"
                          bg="#F2F2F2"
                          border="1px dashed #8C8080"
                          borderRadius="4px"
                          cursor="pointer"
                          onClick={() => {
                            setIsCanvasVis(true);
                          }}
                        >
                          <Box
                            fontFamily="'FedraSansStd-book'"
                            fontSize="16px"
                            lineHeight="136%"
                            color="#4872DF"
                            py="10px"
                            pos="absolute"
                            top="50%"
                            left="50%"
                            transform="translate(-50%, -50%)"
                          >
                            Click to sign
                          </Box>
                        </Box>
                      )}
                      {isCanvasVis && (
                        <Box
                          width="300px"
                          height="130px"
                          border="1px dashed #8C8080"
                          borderRadius="4px"
                        >
                          <SignatureCanvas
                            penColor="black"
                            canvasProps={{
                              width: 300,
                              height: 130,
                              className: 'sigCanvas',
                            }}
                            ref={sigRef}
                            onEnd={handleSignatureEnd}
                          />
                          {isDirty && parentSignature && (
                            <Box onClick={clearSignature} cursor="pointer">
                              Clear Signature
                            </Box>
                          )}
                        </Box>
                      )}
                      {isDirty && submitted && !parentSignature && (
                        <Box
                          fontFamily="FedraSansStd-medium"
                          lineHeight="136%"
                          color="#E53E3E"
                          py="10px"
                          fontSize="sm"
                        >
                          {t('validationMessages.required')}
                        </Box>
                      )}
                    </Box>
                  </>
                )}

              {pageName !== 'yo' &&
                pageName !== 'army' &&
                pageName !== 'corporate-register' && (
                  <Box my="30px">
                    <Box
                      mb="10px"
                      fontSize="14px"
                      color="#5B5237"
                      textAlign={lang == 'ar' && 'right'}
                      dir={lang == 'ar' ? 'rtl' : 'ltr'}
                    >
                      {t('formFields.haveaVoucherCode')}
                    </Box>
                    <Flex
                    // background="#F1EBDF"
                    >
                      <HookFormTextField
                        name={'cCode'}
                        color={'#CF4520'}
                        label={t('formFields.enterCode')}
                        fontSize={{ base: '14px', md: '16px' }}
                        disabled={couponState.message || couponState.msg}
                        w={{ base: '100%', md: '300px' }}
                        rules={{
                          pattern: {
                            value: couponCodeValidation.value,
                            message: t(couponCodeValidation.message),
                          },
                          // validate: {
                          //   length: value =>
                          //     watchCcode && !couponState.msg ? t('Voucher Code not applied')
                          //       : null,
                          // },
                          minLength: 3,
                          maxLength: 100,
                        }}
                        // invalidMessage={t(
                        //   'validationMessages.voucherCode.invalid'
                        // )}
                        testId={'enterCode-input'}
                        maxW="300px"
                      />

                      <Link
                        disabled={couponState.message || couponState.msg}
                        onClick={() => {
                          verifyCouponCode();
                        }}
                        zIndex="3"
                        color={ccCodeColor}
                        fontWeight="400"
                        fontSize={{ base: '14px', md: '16px' }}
                        position="absolute"
                        padding="7px 5px"
                        background="#F1EBDF"
                        h="38px"
                        mt="1px"
                        ml={{ base: '57%', sm: '214px', md: '224px' }}
                        width="75px"
                        textAlign="center"
                        fontFamily="'FedraSansStd-book', sans-serif"
                        _hover={{
                          textDecoration: 'none',
                        }}
                      >
                        {couponState.message || couponState.msg
                          ? 'Remove'
                          : 'Apply'}
                      </Link>
                    </Flex>
                    {couponState.message && (
                      <Box
                        fontSize="14px"
                        color="#CF4520"
                        mt="23px"
                        lineHeight="22px"
                      >
                        {couponState.message}
                      </Box>
                    )}
                    {/* {couponState.validationErr && (
                  <Box
                    fontSize="14px"
                    color="#CF4520"
                    mt="23px"
                    lineHeight="22px"
                  >
                    {couponState.validationErr}
                  </Box>
                )} */}

                    {couponState.cpnValid && (
                      <Box
                        fontSize="14px"
                        color="#00A171"
                        mt="23px"
                        lineHeight="22px"
                        display="flex"
                        alignItems="center"
                      >
                        <Image
                          src="https://static.sadhguru.org/d/46272/1699437335-icon_checkmark.svg"
                          mr="3px"
                          mt="3px"
                        />
                        {couponState.msg}
                      </Box>
                    )}
                  </Box>
                )}
            </IeoFormFieldGrid>

            {region === 'in' &&
              pageName === 'yo' &&
              context?.age < 18 &&
              searchByFieldId(sectionContent, 'yo-minor-consent')?.body
                .value && (
                <HookFormCheckboxField
                  lang={lang}
                  name={'yo-minor-consent'}
                  label={
                    <StructuredTextParser
                      mt="-5px"
                      className="linkS"
                      str={render(
                        searchByFieldId(sectionContent, 'yo-minor-consent')
                          ?.body
                      ).replace('{F_Name}', values.fname)}
                      maxW="auto"
                      fontSize="14px"
                      color="#28231E"
                      textAlign={lang === 'ar' && 'right'}
                    />
                  }
                  required={true}
                  testId={'yo-minor-consent-checkbox'}
                />
              )}

            {!isGiftingFlow && (
              <>
                {isIndia && (
                  <>
                    <HookFormCheckboxField
                      py="30px"
                      lang={lang}
                      name={'medical'}
                      label={
                        <StructuredTextParser
                          mt="-5px"
                          className="linkS"
                          str={render(sectionContent?.[2]?.body)}
                          maxW="auto"
                          fontSize="14px"
                          color="#28231E"
                          textAlign={lang === 'ar' && 'right'}
                        />
                      }
                      required={true}
                      testId={'medical-checkbox'}
                    />
                    <Accordion allowMultiple borderColor="rgb(109 99 88 / 30%)">
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box
                              as="span"
                              flex="1"
                              textAlign={lang === 'ar' ? 'right' : 'left'}
                              color="#CF4520"
                              textTransform="uppercase"
                            >
                              {t('formContent.disclaimer')}
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel
                          pb={4}
                          fontFamily="FedraSansStd-book"
                          overflowY="auto"
                          height="200px"
                        >
                          <StructuredTextParser
                            mt="0"
                            str={render(sectionContent?.[4]?.body)}
                            maxW="auto"
                            fontSize="14px"
                            color="#28231E"
                            className="textP"
                            textAlign={lang === 'ar' && 'right'}
                          />
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                    <style jsx global>
                      {`
                        .textP p {
                          padding-bottom: 20px;
                          font-weight: 400;
                        }
                      `}
                    </style>
                  </>
                )}

                {!isIndia && (
                  <>
                    <DisclaimerWaiverField />
                    <ReleaseWaiverField />
                  </>
                )}

                {isIndia && (
                  <>
                    {region === 'in' &&
                      pageName === 'yo' &&
                      context?.age >= 15 &&
                      pageName !== 'army' &&
                      searchByFieldId(sectionContent, 'yo-minor-explore')?.body
                        .value && (
                        <StructuredTextParser
                          mt="0"
                          str={render(
                            searchByFieldId(sectionContent, 'yo-minor-explore')
                              ?.body
                          )}
                          maxW="auto"
                          fontSize="14px"
                          color="#28231E"
                          fontFamily="FedraSansStd-book"
                          textAlign={lang === 'ar' && 'right'}
                        />
                      )}
                    {region === 'in' &&
                      pageName === 'yo' &&
                      pageName !== 'army' &&
                      searchByFieldId(
                        sectionContent,
                        'yo-minor-explore-checkbox'
                      )?.body?.value && (
                        <Box mb="20px !important" mt="15px !important">
                          <HookFormCheckboxField
                            lang={lang}
                            name={'yo-minor-explore-checkbox'}
                            label={
                              <StructuredTextParser
                                mt="-5px"
                                className="linkS"
                                str={render(
                                  searchByFieldId(
                                    sectionContent,
                                    'yo-minor-explore-checkbox'
                                  )?.body
                                )}
                                maxW="auto"
                                fontSize="14px"
                                color="#28231E"
                                textAlign={lang === 'ar' && 'right'}
                              />
                            }
                            required={true}
                            testId={'yo-minor-explore-checkbox'}
                          />
                        </Box>
                      )}
                    {region === 'in' &&
                      pageName === 'yo' &&
                      pageName !== 'army' &&
                      searchByFieldId(sectionContent, 'yo-third-party-checkbox')
                        ?.body?.value && (
                        <Box mt="20px !important">
                          <HookFormCheckboxField
                            lang={lang}
                            name={'yo-third-party-checkbox'}
                            label={
                              <StructuredTextParser
                                mt="-5px"
                                className="linkS"
                                str={render(
                                  searchByFieldId(
                                    sectionContent,
                                    'yo-third-party-checkbox'
                                  )?.body
                                )}
                                maxW="auto"
                                fontSize="14px"
                                color="#28231E"
                                textAlign={lang === 'ar' && 'right'}
                              />
                            }
                            required={true}
                            testId={'yo-third-party-checkbox'}
                          />
                        </Box>
                      )}
                    {region === 'in' &&
                      pageName !== 'army' &&
                      searchByFieldId(
                        sectionContent,
                        'yo-minor-explore-checkbox'
                      )?.body?.value && (
                        <Box mt="20px !important" mb="15px !important">
                          <HookFormCheckboxField
                            lang={lang}
                            name={'yo-minor-explore-checkbox'}
                            label={
                              <StructuredTextParser
                                mt="-5px"
                                className="linkS"
                                str={render(
                                  searchByFieldId(
                                    sectionContent,
                                    'yo-minor-explore-checkbox'
                                  )?.body
                                )}
                                maxW="auto"
                                fontSize="14px"
                                color="#28231E"
                                textAlign={lang === 'ar' && 'right'}
                              />
                            }
                            required={true}
                            testId={'yo-minor-explore-checkbox'}
                          />
                        </Box>
                      )}
                    {region === 'in' && pageName !== 'army' && (
                      <HookFormCheckboxField
                        lang={lang}
                        name={'receiveMessages'}
                        label={
                          <StructuredTextParser
                            mt="-5px"
                            className="linkS"
                            str={render(sectionContent?.[5]?.body)}
                            maxW="auto"
                            fontSize="14px"
                            color="#28231E"
                            textAlign={lang === 'ar' && 'right'}
                          />
                        }
                        required={false}
                        testId={'receiveMessages-checkbox'}
                      />
                    )}
                    {(!context || context?.age >= 18) &&
                      render(sectionContent?.[6]?.body) !== '<p><br></p>' &&
                      render(sectionContent?.[6]?.body) !== '<p></p>' && (
                        <HookFormCheckboxField
                          name={'age'}
                          lang={lang}
                          label={
                            <StructuredTextParser
                              mt="-5px"
                              className="linkS"
                              str={render(sectionContent?.[6]?.body)}
                              maxW="auto"
                              fontSize="14px"
                              color="#28231E"
                              textAlign={lang === 'ar' && 'right'}
                            />
                          }
                          required={true}
                          testId={'age-checkbox'}
                        />
                      )}
                    {pageName !== 'army' && (
                      <HookFormCheckboxField
                        lang={lang}
                        name={'terms'}
                        label={
                          <StructuredTextParser
                            mt="-5px"
                            className="linkS"
                            str={render(sectionContent?.[7]?.body)}
                            maxW="auto"
                            fontSize="14px"
                            color="#28231E"
                            textAlign={lang === 'ar' && 'right'}
                          />
                        }
                        required={true}
                        testId={'terms-checkbox'}
                      />
                    )}{' '}
                  </>
                )}
                {region === 'in' &&
                  pageName === 'army' &&
                  searchByFieldId(
                    sectionContent,
                    'ieo-army-serving-member-text'
                  )?.body?.value && (
                    <Box mt="20px !important" mb="15px !important">
                      <HookFormCheckboxField
                        lang={lang}
                        name={'armyServing'}
                        label={
                          <StructuredTextParser
                            mt="-5px"
                            className="linkS"
                            str={render(
                              searchByFieldId(
                                sectionContent,
                                'ieo-army-serving-member-text'
                              )?.body
                            )}
                            maxW="auto"
                            fontSize="14px"
                            color="#28231E"
                            textAlign={lang === 'ar' && 'right'}
                          />
                        }
                        required={true}
                        testId={'ieo-army-serving-member-text'}
                      />
                    </Box>
                  )}

                {region === 'in' &&
                  pageName === 'army' &&
                  searchByFieldId(sectionContent, 'ieo-army-information-text')
                    ?.body?.value && (
                    <Box mt="20px !important" mb="15px !important">
                      <HookFormCheckboxField
                        lang={lang}
                        name={'participate'}
                        label={
                          <StructuredTextParser
                            mt="-5px"
                            className="linkS"
                            str={render(
                              searchByFieldId(
                                sectionContent,
                                'ieo-army-information-text'
                              )?.body
                            )}
                            maxW="auto"
                            fontSize="14px"
                            color="#28231E"
                            textAlign={lang === 'ar' && 'right'}
                          />
                        }
                        required={true}
                        testId={'ieo-army-information-text'}
                      />
                    </Box>
                  )}
                {region === 'in' &&
                  pageName === 'army' &&
                  searchByFieldId(sectionContent, 'ieo-army-tnc-text')?.body
                    ?.value && (
                    <Box mt="20px !important" mb="15px !important">
                      <HookFormCheckboxField
                        lang={lang}
                        name={'terms'}
                        label={
                          <StructuredTextParser
                            mt="-5px"
                            className="linkS"
                            str={render(
                              searchByFieldId(
                                sectionContent,
                                'ieo-army-tnc-text'
                              )?.body
                            )}
                            maxW="auto"
                            fontSize="14px"
                            color="#28231E"
                            textAlign={lang === 'ar' && 'right'}
                          />
                        }
                        required={true}
                        testId={'ieo-army-tnc-text'}
                      />
                    </Box>
                  )}
                {region === 'in' &&
                  pageName === 'army' &&
                  searchByFieldId(sectionContent, 'ieo-army-privacy-text')?.body
                    ?.value && (
                    <Box mt="20px !important" mb="15px !important">
                      <HookFormCheckboxField
                        lang={lang}
                        name={'privacy'}
                        label={
                          <StructuredTextParser
                            mt="-5px"
                            className="linkS"
                            str={render(
                              searchByFieldId(
                                sectionContent,
                                'ieo-army-privacy-text'
                              )?.body
                            )}
                            maxW="auto"
                            fontSize="14px"
                            color="#28231E"
                            textAlign={lang === 'ar' && 'right'}
                          />
                        }
                        required={true}
                        testId={'ieo-army-privacy-text'}
                      />
                    </Box>
                  )}
                {region === 'in' &&
                  pageName === 'army' &&
                  searchByFieldId(sectionContent, 'ieo-army-receive-msg-text')
                    ?.body?.value && (
                    <Box mt="20px !important" mb="15px !important">
                      <HookFormCheckboxField
                        lang={lang}
                        name={'receiveMessages'}
                        label={
                          <StructuredTextParser
                            mt="-5px"
                            className="linkS"
                            str={render(
                              searchByFieldId(
                                sectionContent,
                                'ieo-army-receive-msg-text'
                              )?.body
                            )}
                            maxW="auto"
                            fontSize="14px"
                            color="#28231E"
                            textAlign={lang === 'ar' && 'right'}
                          />
                        }
                        required={true}
                        testId={'ieo-army-receive-msg-text'}
                      />
                    </Box>
                  )}
                {!isIndia && (
                  <>
                    <HookFormCheckboxField
                      lang={lang}
                      name={'receiveMessages'}
                      label={
                        <StructuredTextParser
                          mt="-5px"
                          className="linkS"
                          str={render(sectionContent?.[10]?.body)}
                          maxW="auto"
                          fontSize="14px"
                          color="#28231E"
                          textAlign={lang === 'ar' && 'right'}
                        />
                      }
                      required={false}
                      testId={'receiveMessages-checkbox'}
                    />

                    <HookFormCheckboxField
                      lang={lang}
                      name={'age'}
                      label={
                        <StructuredTextParser
                          mt="-5px"
                          className="linkS"
                          str={render(sectionContent?.[11]?.body)}
                          maxW="auto"
                          fontSize="14px"
                          color="#28231E"
                          textAlign={lang === 'ar' && 'right'}
                        />
                      }
                      required={true}
                      testId={'terms-checkbox'}
                    />

                    <HookFormCheckboxField
                      lang={lang}
                      name={'privacy'}
                      label={
                        <StructuredTextParser
                          mt="-5px"
                          className="linkS"
                          str={render(sectionContent?.[12]?.body)}
                          maxW="auto"
                          fontSize="14px"
                          color="#28231E"
                          textAlign={lang === 'ar' && 'right'}
                        />
                      }
                      required={true}
                      testId={'privacy-checkbox'}
                    />
                  </>
                )}
              </>
            )}
            {isGiftingFlow && (
              <>
                <HookFormCheckboxField
                  lang={lang}
                  name={'geoRestriction'}
                  label={
                    <StructuredTextParser
                      mt="-5px"
                      className="linkS"
                      str={render(sectionContent?.[2]?.body)}
                      maxW="auto"
                      fontSize="14px"
                      color="#28231E"
                    />
                  }
                  required={true}
                  testId={'geoRestriction-checkbox'}
                />

                <HookFormCheckboxField
                  lang={lang}
                  name={'codeTransfer'}
                  label={
                    <StructuredTextParser
                      mt="-5px"
                      className="linkS"
                      str={render(sectionContent?.[3]?.body)}
                      maxW="auto"
                      fontSize="14px"
                      color="#28231E"
                    />
                  }
                  required={true}
                  testId={'codeTransfer-checkbox'}
                />

                <HookFormCheckboxField
                  lang={lang}
                  name={'terms'}
                  label={
                    <StructuredTextParser
                      mt="-5px"
                      className="linkS"
                      str={render(sectionContent?.[4]?.body)}
                      maxW="auto"
                      fontSize="14px"
                      color="#28231E"
                    />
                  }
                  required={true}
                  testId={'terms-checkbox'}
                />
              </>
            )}
            <style>
              {`
                .linkS a {
                  color: #CF4520;
                  text-decoration: underline;
                }`}
            </style>
            {pageName !== 'corporate-register' && (
              <>
                <Text
                  textAlign={'center'}
                  color={'#F37021'}
                  fontFamily="FedraSansStd-medium"
                  fontSize="20px"
                >
                  {t('formContent.programFee')}: {program?.curr} {programFee}
                </Text>

                {isIndia && pageName !== 'army' && (
                  <Text
                    textAlign={'center'}
                    fontFamily="FedraSansStd-book"
                    fontWeight="normal"
                  >
                    <StructuredTextParser
                      mt="0px"
                      str={render(sectionContent?.[9]?.body)}
                      maxW="auto"
                      fontSize="14px"
                      color="#28231E"
                      textAlign="center"
                    />
                  </Text>
                )}
              </>
            )}
            {!isIndia && pageName === 'yo' && region === 'in' && (
              <Text
                textAlign={'left'}
                fontFamily="FedraSansStd-book"
                fontSize={{ base: '12px', md: '16px' }}
                color="#6D6358"
              >
                If you are under 18, please have your parent/guardian contact
                <a href="mailto:support.ishafoundation.org">
                  support.ishafoundation.org
                </a>
                to complete the registration process for you.
              </Text>
            )}

            {pageName === 'corporate-register' ? (
              <Box mt={3} textAlign={'center'}>
                {errorMessage && clickedSubmit && (
                  <FormErrorMessage>{errorMessage}</FormErrorMessage>
                )}
                <SubmitButton
                  label={t('formFields.completeRegistration')}
                  isSubmitting={isSubmitting}
                  testId={'step2-submit'}
                  onClick={() => {
                    trigger();
                    setClickedSubmit(true);
                  }}
                />
              </Box>
            ) : (
              <Box mt={3} textAlign={'center'}>
                {errorMessage && clickedSubmit && (
                  <FormErrorMessage>{errorMessage}</FormErrorMessage>
                )}
                <SubmitButton
                  label={t(
                    programFee === 0
                      ? 'formFields.completeRegistration'
                      : 'formFields.paySecurely'
                  )}
                  isSubmitting={isSubmitting}
                  testId={'step2-submit'}
                  onClick={() => {
                    trigger();
                    setClickedSubmit(true);
                    gtmClick();
                  }}
                />
              </Box>
            )}
          </>
        )}
      </VStack>
    </form>
  );
}
